<template>
  <div id="cutbottomLeft">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">当日领料信息</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box"  style="height:90%; font-size: 0.5rem;color:#ffc103" >
        <dv-scroll-board :config="config"  />
      </div>
    </div>
  </div>
</template>

<script>
import {findCutList} from '../../libs/axios';
export default {
  data() {
    return {
      config: {
        rowNum: 8, //表格行数
        header: ["领料人", "领料时间", "半成品名称", "领取数量"],
        data:[],
        headerHeight: 65,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true, 
        columnWidth: [100,180,180,500,200],
        align: ["center"]
      }
    };
  },
  components: {},
  mounted() {
    this.init();
     setInterval(this.init,120000);
  },
  methods: {
    init() {
      this.getWageList();
    },
     getWageList() {
       
      this.loading = true;
      findCutList({wareType:1,speciesType:3}).then((res) => {
          this.loading = false;
          this.$set(this.config,  this.config.data,res.data);
          this.config.data=res.data;
      });
    },
  }
};
</script>

<style lang="scss">
#cutbottomLeft {
  padding: 0.2rem;
  height:9.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  font-size: 0.5rem;
  .bg-color-black {
    height:8.125rem;
    border-radius: 0.125rem;
    font-size:0.5rem;
  }
  .text {
    font-size: 0.5rem;
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
    font-size: 0.3rem;
  }
  .header-item{
    font-size: 0.3rem;
  }
    .row-item{
    font-size: 0.3rem;
    color:"#ffc103"
  }
  .ceil{
    color: rgb(255, 193, 3);
  }
}
</style>