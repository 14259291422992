<template>
  <div id="cutTop">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">裁床数据统计</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box"  style="height:100%; font-size: 0.5rem;color:#ffc103" >
        <dv-scroll-board :config="config"  />
      </div>
    </div>
  </div>
</template>

<script>
import {selectCutTotal} from '../../libs/axios';
export default {
  data() {
    return {
      config: {
        rowNum: 1, //表格行数
        data:[],
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: false, 
        waitTime: 20000,
        columnWidth: [230],
        align: ["center"]
      }
    };
  },
  components: {},
  mounted() {
    this.init();
     setInterval(this.init,120000);
  },
  methods: {
    init() {
      this.getWageList();
    },
     getWageList() {
       
      this.loading = true;
      selectCutTotal().then((res) => {
          this.loading = false;
          this.$set(this.config,  this.config.data,res.data);
          this.config.data=res.data;
      });
    },
  }
};
</script>

<style lang="scss">
#cutTop {
    display: flex;
  flex-direction: column;
  padding: 0.2rem;
  height: 4.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  font-size: 0.5rem;
  .bg-color-black {
    height: 3.125rem;
    border-radius: 0.125rem;
    font-size:0.5rem;
  }
  .text {
    font-size: 0.5rem;
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
    font-size: 0.5rem;
  }
  .header-item{
    font-size: 0.4rem;
  }
    .row-item{
    font-size: 0.5rem;
    color:"#ffc103"
  }
  .ceil{
    color: rgb(255, 193, 3);
  }


  .box-s{
    background-color: white;
    width: 19%;
    margin: 5px 5px;
    height: 120px;
    float: left;
  }
  .box-h{
    height: 100%;
        line-height: 125px;
    text-align: center;
  }
    .box-l{
      
    float:left;
    width: 40%;
    text-align: center;
    
  }
  .box-r{
    
    
    float:left;
    width: 60%;
    text-align: center;
  }
    .box-rt{
    height:50%;
    line-height: 70px;
    font-size: 24px;
    
    
  }
      .box-rd{
    height:50%;
    line-height: 40px;
    font-size: 24px;
  }
  .ivu-icon {
    display: inline-block;
    font-family: Ionicons;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: optimizeLegibility;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: -.125em;
    text-align: center;
  }
  .i-icon{
    color: rgb(44, 42, 42);
  }
  .hh-t{
        height: 100%;
    width: 100%;
    line-height: normal;
    display:table-cell;
    height:240px;
     vertical-align:middle;
     text-align:center
  }
  .hh-qz{
    white-space:normal; 
    color: #3fdb8c;
  }
}
</style>